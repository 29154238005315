/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Table from '../../../../components/organisms/Table';
import { tableHeader, BloodSurvey, tableHeadeBlk } from '../TASData';
import CustomModal from '../../../../components/molecules/Modal';
import { INSERT_TAS, GET_TAS, BULKUPLOAD_TAS, GET_MASTER_TEMPLATES } from '../../../../constants/actionType';
import CustomForm from '../../../../components/organisms/CustomForm';
import './TASTable.css';

/**
 * @param {Function} onCancelAction --on click cancel button
 * @returns {React.ReactElement} - returns the IDA input screen
 */
const TASTable = ({ onCancelAction, TASType, Activity, jsonData, field, setIsJson, isJson }) => {
  const [TableData, setTableData] = useState([]);
  const [updateEntries, setUpdateEntries] = useState([]);
  const [formDefaultValues, setFormDefaultValues] = useState({});
  const [viewDetails, setViewDetails] = useState({ View: false, ViewIndex: 0, selectedView: '' });
  const dispatch = useDispatch();
  const userAurhId = localStorage.getItem('UserLoginId');

  // console.log(Activity, 'TableData');
  useEffect(() => {
    dispatch({
      type: GET_TAS,
      payload: {
        proposed_program_activities: TASType,
        strategy: field,
      },
    });
  }, [dispatch]);

  const TASData = useSelector((ReducerState) => ReducerState.TASDetails.TASGetData);
  /**
   * @param {Array} Data input view data
   * @param {number} Index input view data index
   * @param {string} Type input view data index
   */
  // const onView = (Data, Index, Type) => {
  //   setViewDetails({ selectedView: Type, View: true, ViewIndex: Index });
  //   const formData = [];
  //   const default1 = {};
  //   if (Data.length === 0) {
  //     formData.push({ formTitle: true, labelText: 'No Data Found !' });
  //   }
  //   if (Type === 'eu') {
  //     Data.map((data, index) => {
  //       if (index === 0) {
  //         formData.push({ formTitle: true, labelText: 'EU Details' });
  //       }
  //       default1[`EU_Name${index + 1}`] = data.eu_name;
  //       formData.push({
  //         default: true,
  //         textField: true,
  //         entryName: `EU_Name${index + 1}`,
  //         placeholder: `EU Name${index + 1}`,
  //         textInputType: 'default',
  //         validation: { isRequired: true },
  //       });
  //       data.blockDetails.map((blockData, blockDataIndex) => {
  //         default1[`Block_Name_EU${index + 1}_${blockDataIndex + 1}`] = blockData.block_name;
  //         default1[`Population_EU${index + 1}_${blockDataIndex + 1}`] = blockData.population;
  //         formData.push(
  //           {
  //             default: true,
  //             textField: true,
  //             entryName: `Block_Name_EU${index + 1}_${blockDataIndex + 1}`,
  //             placeholder: `Block Name ${blockDataIndex + 1}`,
  //             textInputType: 'default',
  //             validation: { isRequired: true },
  //           },
  //           {
  //             default: true,
  //             textField: true,
  //             entryName: `Population_EU${index + 1}_${blockDataIndex + 1}`,
  //             placeholder: 'Population',
  //             textInputType: 'default',
  //             validation: { isRequired: true },
  //           },
  //         );
  //         return true;
  //       });
  //       default1[`Population_Under_EU${index + 1}`] = data.population_under_eu;
  //       formData.push(
  //         {
  //           default: true,
  //           textField: true,
  //           entryName: `Population_Under_EU${index + 1}`,
  //           placeholder: 'Population Under EU',
  //           textInputType: 'default',
  //           validation: { isRequired: true },
  //         },
  //         {
  //           divider: true,
  //           fullView: true,
  //         },
  //       );
  //       return true;
  //     });
  //   } else {
  //     Data?.map((data, index) => {
  //       if (index === 0) {
  //         formData.push({ formTitle: true, labelText: 'TAS Blood Survey' });
  //       }
  //       for (let i = 1; i <= 5; i++) {
  //         default1[`${BloodSurvey[i - 1].name}${index + 1}`] = data[BloodSurvey[i - 1].key];
  //         formData.push({
  //           default: true,
  //           textField: true,
  //           entryName: `${BloodSurvey[i - 1].name}${index + 1}`,
  //           placeholder: `${BloodSurvey[i - 1].placeholder}`,
  //           textInputType: 'default',
  //           validation: { isRequired: true },
  //         });
  //       }

  //       formData.push({
  //         divider: true,
  //         fullView: true,
  //       });
  //       return true;
  //     });
  //   }
  //   setFormDefaultValues(default1);
  //   setUpdateEntries(formData);
  // };
  // console.log(jsonData, 'field');
  // console.log(TASData, 'TASData');
  useEffect(() => {
    // let sitesRow = {};
    if (field === 'District Strategy') {
      const TableResponse = TASData.map((data, index) => {
        // console.log(data, 'sdetw');
        const tableObj = {
          s_no: index + 1,
          state: data.state,
          state_id: data?.state_id,
          district: data.district,
          district_id: data?.district_id,
          block_strategy: data.block_strategy,
          date_proposed: data.date_proposed,
          date_planned: data.date_planned,
          date_completed: data.date_completed,
          eu_count: data.eu_count,
          tas_id: data?.tas_id,

          // eu_view: (
          //   <div
          //     tabIndex={index}
          //     role="button"
          //     style={{ cursor: 'pointer' }}
          //     onClick={() => onView(data.tasBlockCount, index, 'eu')}
          //   >
          //     View
          //   </div>
          // ),
          // blood_survey_view: (
          //   <div
          //     tabIndex={index}
          //     role="button"
          //     style={{ cursor: 'pointer' }}
          //     onClick={() => onView(data.tasBloodSurvey, index, 'blood_survey')}
          //   >
          //     View
          //   </div>
          // ),
        };
        data?.TasDetails?.map((item, i) => {
          Object.assign(tableObj, {
            [`Eu_Name${i}`]: item.eu_name,
            [`block_date_proposed${i}`]: item.block_date_proposed,
            [`block_date_completed${i}`]: item.block_date_completed,

            [`block_date_planned${i}`]: item.block_date_planned,
            // [`block_date_completed${i}`]: item.block_date_completed,
            [`population_under_eu${i}`]: item.population_under_eu,
            [`sample_size${i}`]: item.sample_size,
            [`number_of_persons_examined${i}`]: item.number_of_persons_examined,
            [`number_of_persons_positive${i}`]: item.number_of_persons_positive,
            [`number_of_invalid_test${i}`]: item.number_of_invalid_test,
            [`critical_cut_off${i}`]: item.critical_cut_off,
            tas_eu_block_count_id: item?.tas_eu_block_count_id,
          });
        });
        return tableObj;
      });
      setTableData(TableResponse);
    }
    if (field === 'Block Strategy') {
      const TableResponse = TASData.map((data, index) => {
        // console.log(data, 'sdetw');
        const tableObj = {
          s_no: index + 1,
          state: data.state,
          state_id: data?.state_id,
          district: data.district,
          district_id: data?.district_id,
          block_strategy: data.block_strategy,
          date_proposed: data.date_proposed,
          date_planned: data.date_planned,
          date_completed: data.date_completed,
          eu_count: data.eu_count,
          // eu_view: (
          //   <div
          //     tabIndex={index}
          //     role="button"
          //     style={{ cursor: 'pointer' }}
          //     onClick={() => onView(data.tasBlockCount, index, 'eu')}
          //   >
          //     View
          //   </div>
          // ),
          // blood_survey_view: (
          //   <div
          //     tabIndex={index}
          //     role="button"
          //     style={{ cursor: 'pointer' }}
          //     onClick={() => onView(data.tasBloodSurvey, index, 'blood_survey')}
          //   >
          //     View
          //   </div>
          // ),
        };
        data?.TasDetails?.map((item, i) => {
          Object.assign(tableObj, {
            [`Eu_Name${i}`]: item.eu_name,
            [`block_date_proposed${i}`]: item.block_date_proposed,
            [`block_date_completed${i}`]: item.block_date_completed,

            [`block_date_planned${i}`]: item.block_date_planned,
            // [`block_date_completed${i}`]: item.block_date_completed,
            [`population_under_eu${i}`]: item.population_under_eu,
            [`sample_size${i}`]: item.sample_size,
            [`number_of_persons_examined${i}`]: item.number_of_persons_examined,
            [`number_of_persons_positive${i}`]: item.number_of_persons_positive,
            [`number_of_invalid_test${i}`]: item.number_of_invalid_test,
            [`critical_cut_off${i}`]: item.critical_cut_off,
          });
          // item?.TasBlocks.map((val, indexs) => {
          //   console.log(val, indexs, 'kskdeirt');
          //   Object.assign(tableObj, {
          //     [`block_name${indexs}`]: val.block_name,
          //     [`population${indexs}`]: val.population,
          //   });
          // });
        });
        return tableObj;
      });
      setTableData(TableResponse);
    }
  }, [TASData]);
  // console.log(TableData, 'tsasdtt');
  /**
   *
   * @returns
   */
  const getTasUploadDis = () => {
    const Obj = [];
    jsonData?.map((item) => {
      const arr = [];
      for (let i = 0; i < item?.eu_count; i++) {
        arr.push({
          eu_name: item[`Eu_Name${i}`],
          population_under_eu: item[`population_under_eu${i}`],
          date_proposed: moment(new Date((item[`block_date_proposed${i}`] - (25567 + 2)) * 86400 * 1000)).format(
            'YYYY-MM-DD',
          ),
          date_planned: moment(new Date((item[`block_date_planned${i}`] - (25567 + 2)) * 86400 * 1000)).format(
            'YYYY-MM-DD',
          ),
          date_completed: moment(new Date((item[`block_date_completed${i}`] - (25567 + 2)) * 86400 * 1000)).format(
            'YYYY-MM-DD',
          ),
          sample_size: item[`sample_size${i}`],
          number_of_persons_examined: item[`number_of_persons_examined${i}`],
          number_of_persons_positive: item[`number_of_persons_positive${i}`],
          number_of_invalid_test: item[`number_of_invalid_test${i}`],
          critical_cut_off: item[`critical_cut_off${i}`],
        });
      }
      Obj.push({
        proposed_program_activities: Activity,
        state_id: item?.state,
        district_id: item?.district,
        block_strategy_id: item?.block_strategy,
        date_proposed: moment(new Date((item?.date_proposed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        date_planned: moment(new Date((item?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        date_completed: moment(new Date((item?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        eu_count: item?.eu_count,
        population_user_tas: '2000',
        tasBloodSurvey: arr,
      });
    });
    return Obj;
  };
  /**
   *
   * @returns
   */
  const getTasUploadBlk = () => {
    const Obj = [];
    jsonData?.map((item) => {
      // console.log(item, 'slkdijreowee');
      const arr = [];
      for (let i = 0; i <= 5; i++) {
        arr.push({
          block_name: `R${i + 1}`,
          population: item[`population_under_eu${i}`],
        });
      }
      const arr1 = [];
      for (let i = 0; i < item?.eu_count; i++) {
        arr1.push({
          eu_name: item[`Eu_Name${i}`],
          population_under_eu: item[`population_under_eu${i}`],
          date_proposed: moment(new Date((item[`block_date_proposed${i}`] - (25567 + 2)) * 86400 * 1000)).format(
            'YYYY-MM-DD',
          ),
          date_planned: moment(new Date((item[`block_date_planned${i}`] - (25567 + 2)) * 86400 * 1000)).format(
            'YYYY-MM-DD',
          ),
          date_completed: moment(new Date((item[`block_date_completed${i}`] - (25567 + 2)) * 86400 * 1000)).format(
            'YYYY-MM-DD',
          ),
          sample_size: item[`sample_size${i}`],
          number_of_persons_examined: item[`number_of_persons_examined${i}`],
          number_of_persons_positive: item[`number_of_persons_positive${i}`],
          number_of_invalid_test: item[`number_of_invalid_test${i}`],
          critical_cut_off: item[`critical_cut_off${i}`],
          Blocks: arr,
        });
      }
      Obj.push({
        proposed_program_activities: Activity,
        state_id: item?.state,
        district_id: item?.district,
        block_strategy_id: item?.block_strategy,
        date_proposed: moment(new Date((item?.date_proposed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        date_planned: moment(new Date((item?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        date_completed: moment(new Date((item?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        eu_count: item?.eu_count,
        block_count: '1',
        population_user_tas: '100',
        tasBloodSurvey: arr1,
      });
    });
    return Obj;
  };
  /**
   *
   * @param {*} formData - value of table data
   */
  const onUpdateTableModel = (formData) => {
    const SelectData = TASData[viewDetails.ViewIndex];
    let final;
    if (viewDetails.selectedView === 'eu') {
      const tasBlock = [];
      for (let count = 1; count <= SelectData.eu_count; count++) {
        if (SelectData.tasBlockCount?.length > 0) {
          if (SelectData.tasBlockCount[count - 1].blockDetails) {
            for (let i = 1; i <= SelectData.tasBlockCount[count - 1].blockDetails.length; i++) {
              tasBlock.push({
                eu_name: formData[`EU_Name${count}`],
                block_name: formData[`Block_Name_EU${count}_${i}`] && formData[`Block_Name_EU${count}_${i}`],
                population: formData[`Population_EU${count}_${i}`],
                population_under_eu: formData[`Population_Under_EU${count}`] && formData[`Population_Under_EU${count}`],
                tas_eu_block_count_id: SelectData.tasBlockCount[count - 1].blockDetails[i - 1].tas_eu_block_count_id,
                delete_status: '0',
              });
            }
          } else {
            tasBlock.push({
              eu_name: formData[`EU_Name${count}`],
              population_under_eu: formData[`Population_Under_EU${count}`] && formData[`Population_Under_EU${count}`],
              tas_eu_block_count_id: SelectData.tasBlockCount[count - 1].tas_eu_block_count_id,
              delete_status: '0',
            });
          }
        }
      }
      final = {
        ...SelectData,
        tasBlockCount: tasBlock,
      };
    } else {
      const tasBloodSurvey = [];
      for (let count = 1; count <= SelectData.tasBloodSurvey.length; count++) {
        tasBloodSurvey.push({
          sample_size: formData[`Sample_Size${count}`],
          number_of_persons_examined:
            formData[`Number_Of_Person_Examined${count}`] && formData[`Number_Of_Person_Examined${count}`],
          number_of_persons_positive: formData[`Number_Of_Person_Positive${count}`],
          number_of_invalid_test:
            formData[`Number_Of_Invalid_Test${count}`] && formData[`Number_Of_Invalid_Test${count}`],
          critical_cut_off: formData[`Critical_Cut_Off${count}`] && formData[`Critical_Cut_Off${count}`],
          tas_blood_survey_id: SelectData.tasBloodSurvey[count - 1]?.tas_blood_survey_id,
        });
      }
      final = {
        ...SelectData,
        tasBloodSurvey,
      };
    }
    dispatch({
      type: INSERT_TAS,
      payload: { type: 'update_by_id', data: final },
    });
    setViewDetails({ ...viewDetails, View: false });
  };

  /**
   *
   * @param {*} key - value of table data
   * @param {*} EditedItems - value of table data
   */
  const onUpdateTableData = (key, EditedItems) => {
    // console.log(EditedItems, 'editItems');
    const UpdateTable = EditedItems?.map((UpdateData) => {
      // console.log(UpdateData, 'skdiert');
      const arr = [];
      const arr1 = [];
      for (let i = 0; i <= 10; i++) {
        arr.push({
          eu_name: UpdateData[`Eu_Name${i}`],
          population_under_eu: UpdateData[`population_under_eu${i}`],
          date_proposed: UpdateData[`block_date_proposed${i}`],
          date_planned: UpdateData[`block_date_planned${i}`],
          date_completed: UpdateData[`block_date_completed${i}`],
          tas_eu_block_count_id: UpdateData.tas_eu_block_count_id,
          delete_status: '0',
        });
        arr1.push({
          sample_size: UpdateData[`sample_size${i}`],
          number_of_persons_examined: UpdateData[`number_of_persons_examined${i}`],
          number_of_persons_positive: UpdateData[`number_of_persons_positive${i}`],
          number_of_invalid_test: UpdateData[`number_of_invalid_test${i}`],
          critical_cut_off: UpdateData[`critical_cut_off${i}`],
          tas_blood_survey_id: UpdateData.tas_id,
        });
      }

      return {
        ...UpdateData,
        tas_id: TASData[UpdateData.s_no - 1]?.tas_id,
        proposed_program_activities: TASType,
        block_count: '5',
        population: '2222',
        tasBlockCount: arr,
        tasBloodSurvey: arr1,
      };
    });
    if (jsonData?.length > 0) {
      dispatch({
        type: BULKUPLOAD_TAS,
        payload: {
          data: jsonData?.[0]?.block_strategy === 'District Strategy' ? getTasUploadDis() : getTasUploadBlk(),
          statergy: jsonData?.[0]?.block_strategy,
        },
      });
      dispatch({ type: GET_MASTER_TEMPLATES });
      setTimeout(() => {
        dispatch({
          type: GET_TAS,
          payload: {
            proposed_program_activities: TASType,
            strategy: field,
          },
        });
      }, 500);
      setIsJson(false);
    } else {
      dispatch({
        type: INSERT_TAS,
        payload: { type: 'update', data: { overAllTasUpdate: UpdateTable, updated_by: userAurhId && userAurhId } },
      });
    }
  };
  // console.log(jsonData, 'ksdfooaqw');
  return (
    <>
      <Table
        header={field === 'District Strategy' ? tableHeader : tableHeadeBlk}
        rows={isJson ? jsonData : TableData}
        slideData
        inputTable
        onCancel={() => onCancelAction('form')}
        onSendUpdatedData={(key, EditedItems) => onUpdateTableData(key, EditedItems)}
        Activity={Activity}
        uniqueKey="tas_id"
        hideKeyFields={['tas_eu_block_count_id', 'tas_id', 'state_id', 'district_id']}
        IsExcelData={isJson}
        screen="input"
      />
      <CustomModal
        openModal={viewDetails.View}
        modalContent={
          <Grid container item md={12} className="formContainer">
            <CustomForm
              entries={updateEntries}
              defaultValues={formDefaultValues}
              onReceiveData={onUpdateTableModel}
              onCancelAction={() => setViewDetails({ ...viewDetails, View: false })}
              masterSetup
            />
          </Grid>
        }
      />
    </>
  );
};
TASTable.propTypes = {
  onCancelAction: PropTypes.func,
  TASType: PropTypes.string.isRequired,
  Activity: PropTypes.string,
};
TASTable.defaultProps = {
  onCancelAction: () => {},
  Activity: '',
};
export default TASTable;
